import {
  Card,
  Box,
  Grid,
  Typography,
  Button
} from '@material-ui/core'
import { Launch } from '@material-ui/icons'
import User from '../../../components/Text/User'
import Drone from '../../../components/Text/Drone'
import { getDecodedAccessToken } from '../../../api/accounts'
import { commonFormat, meterToFeet2dp } from '../../../utils'
import { useMemo, useState } from 'react'
import {format} from 'date-fns'
import ApprovalDialog from '../../Reservation/Approval/ApprovalDialog'

const displayAlt = (alt) => `${Math.round(alt*100)/100}m, ${meterToFeet2dp(alt)}ft`

function DateApprovalEdits(v) {
  const { frz, approvalEdits, lastApprovalEdit, field } = v

  const renderReferenceText = (ref) => <>
   { ref.validity && 
    <Typography variant='body1'>
      {format(new Date(Array.isArray(ref.validity) ? ref.validity[0][field] : ref.validity[field]), commonFormat)}
    </Typography> }
  </>

  if (approvalEdits?.validity && approvalEdits?.validity[field]) {
    return <Typography variant='body1'>
      {approvalEdits?.validity && approvalEdits?.validity[field] && <s>{renderReferenceText(frz)}</s>}
      {approvalEdits?.validity && approvalEdits?.validity[field] && renderReferenceText(approvalEdits)}
    </Typography>
  }

  if (lastApprovalEdit?.validity && lastApprovalEdit?.validity[0][field]) {
    return <Typography variant='body1'>
      {lastApprovalEdit?.validity && lastApprovalEdit?.validity[0][field] && <s>{renderReferenceText(frz)}</s>}
      {lastApprovalEdit?.validity && lastApprovalEdit?.validity[0][field] && renderReferenceText(lastApprovalEdit)}
    </Typography>
  }
  return <Typography variant='body1'>{renderReferenceText(frz)}</Typography>
}
function AltitudeApprovalEdits({ frz, approvalEdits, lastApprovalEdit, field }) {
  const renderReferenceText = (ref) => <>
    AMSL: {displayAlt(ref.altitude[field])} <br />
    {frz.altitude.ground_point &&
      'AGL: ' + displayAlt(ref.altitude[field] - frz.altitude.ground_point.alt)
    }
  </>
  if (approvalEdits?.altitude && approvalEdits?.altitude[field]) {
    return <Typography variant='body1'>
      {approvalEdits?.altitude && approvalEdits?.altitude[field] && <s>{renderReferenceText(frz)}</s>}<br/>
      {approvalEdits?.altitude && approvalEdits?.altitude[field] && renderReferenceText(approvalEdits)}
    </Typography>
  }
  if (lastApprovalEdit?.altitude && lastApprovalEdit?.altitude[field]) {
    return <Typography variant='body1'>
      {lastApprovalEdit?.altitude && lastApprovalEdit?.altitude[field] && <s>{renderReferenceText(frz)}</s>}<br/>
      {lastApprovalEdit?.altitude && lastApprovalEdit?.altitude[field] && renderReferenceText(lastApprovalEdit)}
    </Typography>
  }

  return <Typography variant='body1'>{renderReferenceText(frz)}</Typography>
}

function FrzDetails({ frz, setApprovalEdits, approvalEdits }) {
  const user = getDecodedAccessToken()

  const isApprover = frz.status === 'pending-approval' && (frz.approval_chain?.find((ac) => ac?.approver_user_id === user.user_id) || frz.approval_chain?.find((ac) => ac.approver_mdf_role.find((role)=> user.app_roles.my_drone_fleets.includes(role))))
  const [openApprovalDialog, setOpenApprovalDialog] = useState(false)
  const [frzOk, setFrzOk] = useState(true)

  const lastApprovalEdit = useMemo(() => frz?.approval_chain?.reduce((prev, ac) => ({...prev, ...ac?.approval_edits}), {}) || null, [frz])

  return (
    <Card>
      <Box display='flex' alignItems='center' marginLeft={2} marginTop={2}>
        <Typography variant='h5'>Reservation Details</Typography>
        <Box mr="auto" />

        {
          isApprover && <Box mr={2}>
            <Button variant='outlined' color='inherit' onClick={
              () => {
                setOpenApprovalDialog(true)
              }
            }>Edit Details</Button>
          </Box>
        }
        {frz &&
          <ApprovalDialog
            approvalEdits={approvalEdits}
            frz={frz}
            frzOk={frzOk}
            setFrzOk={setFrzOk}
            open={openApprovalDialog}
            onClose={() => {
              setOpenApprovalDialog(false)
            }}
            onSave={(v) => {
              setOpenApprovalDialog(false)
              setApprovalEdits(v)
            }}
          />
        }
      </Box>
      <Box p={3}>
        <Grid container>
          <Grid item lg={2} md={4} xs={6}>
            <Typography variant='subtitle1'>
              <b>Additional Comments</b>
            </Typography>
            <Typography variant='body1'>
              {frz.description || 'No description provided'}
            </Typography>
          </Grid>
          <Grid item lg={2} md={4} xs={6}>
            <Typography variant='subtitle1'>
              <b>Submitted Permits</b>
            </Typography>
            <Typography variant='body1'>
              {frz.permits?.length > 0
                ? <div>
                  <ul>
                    {
                      Object.entries(frz.permits?.reduce((map, permit) => {
                        map[permit.authority] = (map[permit.authority] ?? 0) + 1
                        return map
                      }, {})).map(([authority, count]) => <li>{authority} ({count})</li>)
                    }
                  </ul>
                  <Button
                    variant='outlined'
                    endIcon={<Launch />}>
                    View All Permits
                  </Button>
                </div>
                : 'No permits uploaded'
              }
            </Typography>
          </Grid>
          <Grid item lg={2} md={4} xs={6}>
            <Typography variant='subtitle1'>
              <b>Pilot(s)</b>
            </Typography>
            {
              frz.pilots?.map(pilot =>
                <Typography variant='body1'>
                  <User id={pilot.user_id} name email />
                </Typography>
              )
            }
          </Grid>
          <Grid item lg={2} md={4} xs={6}>
            <Typography variant='subtitle1'>
              <b>Drone(s)</b>
            </Typography>
            <Typography variant='body1'>
              {
                frz.aircrafts?.map((aircraft) => {
                  return <> <Drone id={aircraft.drone_id} name model /> <br /> </>
                })
              }

            </Typography>
          </Grid>
          <Grid item lg={2} md={4} xs={6}>
            <Grid container direction='column' spacing={1}>
              <Grid item>
                <Typography variant='subtitle1'>
                  <b>Min Altitude</b>
                </Typography>
                { frz.altitude !== undefined && <AltitudeApprovalEdits frz={frz} approvalEdits={approvalEdits} field='min' lastApprovalEdit={lastApprovalEdit} /> }
              </Grid>
              <Grid item>
                <Typography variant='subtitle1'>
                  <b>Max Altitude</b>
                </Typography>
                <Typography variant='body1'>
                  { frz.altitude !== undefined && <AltitudeApprovalEdits frz={frz} approvalEdits={approvalEdits} field='max' lastApprovalEdit={lastApprovalEdit} /> }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={4} xs={6}>
            <Grid container direction='column' spacing={2}>
              <Grid item>
                <Typography variant='subtitle1'>
                  <b>Start Date & Time</b>
                </Typography>
                <DateApprovalEdits frz={frz} approvalEdits={approvalEdits} field='start' lastApprovalEdit={lastApprovalEdit}></DateApprovalEdits>
              </Grid>
              <Grid item>
                <Typography variant='subtitle1'>
                  <b>End Date & Time</b>
                </Typography>
                <DateApprovalEdits frz={frz} approvalEdits={approvalEdits} field='end' lastApprovalEdit={lastApprovalEdit}></DateApprovalEdits>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

export default FrzDetails