import { useState, Fragment } from 'react'

import TopXCloseButton from '../../../components/TopXCloseButton'
// import GridTitle from '../../components/GridTitle'
// import GridLabel from '../../components/GridLabel'
// import GridField from '../../components/GridField'
import {
  IconButton,
  Grid,
  Typography,
} from '@material-ui/core'
import {
  Edit
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    position: 'relative'
  },
  title: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.background.paper,
  },
  editBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  actions: {
    position: 'absolute',
    top: theme.spacing(5),
    right: 0,
  },
})

function FlightPostFlight({ classes, flight }) {

  const [ edit, setEdit ] = useState(false)

  return edit ? (
    <div className={classes.root}>
      <TopXCloseButton onClick={e => setEdit(false)} />
      <div className={classes.title}>
        <Typography variant='h6'>PostFlight Information</Typography>
      </div>
    </div>
  ) : (
    <div className={classes.root}>
      <IconButton onClick={e => setEdit(true)} className={classes.editBtn}>
        <Edit />
      </IconButton>
      <div className={classes.title}>
        <Typography variant='h6'>PostFlight Information</Typography>
      </div>
      <Grid container style={{ padding: '8px 16px' }}>
      <Grid item xs={12} sm={6}>
        <Typography variant='h6'>
          Results
        </Typography>
        { flight.path && <FlightPath path={flight.path} /> }
        <FlightStats flight={flight} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant='h6'>
          Artefacts
        </Typography>
        <Grid container>
          { flight.logs ?
            flight.logs.map((l, i) => <Fragment key={i}>
              <Grid item xs={4}>{i}</Grid>
              <Grid item xs={8}>{l.type} (download link coming soon)</Grid>
            </Fragment>)
          : 'none' }
        </Grid>

      </Grid>
    </Grid>
      {/*<div className={classes.actions}>
        <Button variant='outlined'>Playback Logs</Button>
      </div>*/}
    </div>
  )
}

function FlightPath({ path }) {
  return (<>
    { path.home    && <Typography>Home:    {JSON.stringify(path.home)}</Typography> }
    { path.arm     && <Typography>Arm:     {new Date(path.arm.t)}</Typography> }
    { path.takeoff && <Typography>Takeoff: {new Date(path.takeoff.t)}</Typography> }
    { path.land    && <Typography>Land:    {new Date(path.land.t)}</Typography> }
    { path.disarm  && <Typography>Disarm:  {new Date(path.disarm.t)}</Typography> }
    {/* TODO - path is supposed to have many keys, to display all of them, ordered by time */}
  </>)
}

function prettyFlightDuration(duration) {
  return (duration.hours ? (duration.hours + ':') : '') +
    duration.minutes.toString().padStart(2, '0') + ':' +
    duration.seconds.toString().padStart(2, '0')
}

function FlightStats({ flight }) {
  return (
    <Grid container>
      <Grid item xs={4}>Servicable</Grid>
      <Grid item xs={8}>{ flight.serviceable === true ? 'Yes' : flight.serviceable === false ? 'No' : 'Unknown' }</Grid>
      <Grid item xs={4}>Duration</Grid>
      <Grid item xs={8}>{ prettyFlightDuration(flight.duration) }</Grid>
      <Grid item xs={4}>Max Distance</Grid>
      <Grid item xs={8}>{ isFinite(flight.distance) ? flight.distance : '-' }m</Grid>
      <Grid item xs={4}>Max Alt AMSL</Grid>
      <Grid item xs={8}>{ isFinite(flight.max_alt) ? flight.max_alt : '-' }m</Grid>
      <Grid item xs={4}>Max Alt AGL</Grid>
      <Grid item xs={8}>{ isFinite(flight.max_agl) ? flight.max_agl : '-' }m</Grid>
      <Grid item xs={4}>Notes</Grid>
      <Grid item xs={8}>{ flight.notes || '-' }</Grid>
    </Grid>
  )
 }

export default withStyles(styles)(FlightPostFlight)
