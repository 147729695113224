import { useEffect, useState, useCallback } from 'react'
import { trackWS } from '../../api/tracker'

function useTracks({
  company_ids,
  cbSetAllDrones,
  cbSetAllTrackers,
}) {
  // Tracks (two list, first list originate from MDF suite, second list is UTM broadcasted)
  const [ tracks,     setTracks     ] = useState({}) // ua_id -> obj (used to be imei -> obj)
  const [ cfmsTracks, setCfmsTracks ] = useState({}) // uasid -> obj (future: rename utmTracks)

  // TODO: How to eliminate old state? track continues to keep the old state around (the last
  //       track for each drone - which might be a good thing for future feature of showing
  //       last found). For now to rely on .online state on the drone/tracker

  const onTrack = useCallback(newTracks => {

    // telem from Aircraft, eg. Cerana or XPRIZE participants
    cbSetAllDrones(prevAD => {
      const droneUpdates = {}
      const now = Date.now()
      newTracks.forEach(track => {
        if (track.ua_telem_type === 'aircraft') {
          if (prevAD[track.ua_id]) {
            droneUpdates[track.ua_id] = {
              ...prevAD[track.ua_id],
              lat: track.lat,
              lng: track.lng,
              yaw: track.yaw,
              online: true,
              last_updated: now,
            }
            // console.log('Aircraft telemetry', track)
          } else
            console.log('Cannot find drone', track)
        }
      })
      return { ...prevAD, ...droneUpdates }
    })

    // telem from Gen 1 / Gen 2 trackers (with suitable conversion to unified telem)
    cbSetAllTrackers(prevAT => {
      const trackerUpdates = {}
      const now = Date.now()
      newTracks.forEach(track => {
        if (track.ua_telem_type === 'tracker') {
          const tracker_id = track.ua_id || track.imei // TODO deprecate use of imei as ID
          if (prevAT[tracker_id]) {
            trackerUpdates[tracker_id] = {
              ...prevAT[tracker_id],
              lat: track.lat,
              lng: track.lng,
              yaw: track.yaw,
              online: true,
              fake: !(track.batt_duration > 0 || track.batt_duration < 0) && track.lat !== '' && track.lng !== '',
              last_updated: now
            }
            // console.log('Tracker telemetry', track)
          } else {
            // Create a pseudo tracker if there are telemetry but not inserted in to database yet
            // This is for trackers that are injected from the backend, as well as tracks
            // from companies that by right use has no access to (but made a backdoor)
            trackerUpdates[track.ua_id] = {
              tracker_id: track.ua_id,
              name: `Unknown ${track.imei ?
                      `IMEI x${track.imei.slice(-4)}` :
                      `UA x${track.ua_id.slice(-4)}`
                    }`,
              imei: track.imei || '',
              lat: track.lat,
              lng: track.lng,
              yaw: track.yaw,
              online: true,
              fake: !(track.batt_duration > 0) && track.lat !== '' && track.lng !== '',
              last_updated: now
            }
            // console.log('Pseudo tracker telemetry', track)
          }
        }
      })
      return { ...prevAT, ...trackerUpdates }
    })
  }, [cbSetAllDrones, cbSetAllTrackers])

  useEffect(() => {
    if (company_ids) {
      (async () => { await trackWS({
        company_ids,
        setTracks,
        setCfmsTracks,
        onTrack,
      }) })()
    }
  }, [company_ids, onTrack])

  return {
    tracks,
    cfmsTracks,
  }
}

export default useTracks
