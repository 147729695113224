import { useState } from 'react'

import ActiveHeader    from './components/ActiveHeader'
import ActiveFlights   from './ActiveFlights'
import ActiveGeofences from './ActiveGeofences'
import UpcomingFlights from './UpcomingFlights'

import MySettingIcon from '../../components/Icons/MySettingIcon'
import {
  Tooltip,
} from '@material-ui/core'
import {
  Flight,
  FlightTakeoff
} from '@material-ui/icons'
import {
  ToggleButton,
  ToggleButtonGroup
} from '@material-ui/lab'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    position: 'fixed',
    zIndex: 1,
    top: 0,
    right: 0,
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(8),
    color: theme.palette.common.white
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    width: theme.spacing(36),
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  open: {
    maxHeight: `calc(80vh - ${theme.spacing(2)}px)`,
    overflow: 'scroll'
  },
  close: {
    maxHeight: 0
  },
  toggles: {
    margin: theme.spacing(1, 2),
  },
})

function FlightCtrlPanel({
  classes,
  tracks,
  summary,
  selectedFlightId,
  setSelectedFlightId,
  selectedGeofenceId,
  onClickGeofence,
  isRsaf,
  violations,
  onTrackerClick,
  onDroneClick,
}) {
  const [ enabled, setEnabled ] = useState([
    'geofences', 'planned-flights', 'active-flights'
  ])

  const [ open, setOpen ] = useState(true)

  function handleEnableChange(e, values) {
    setEnabled(values)
    // to move toggleshowonmap from rsaf control panel
  }

  return (
    <div className={classes.root}>
      <ActiveHeader
        open={open}
        onToggle={e => { setOpen(!open) }}
      />
      <div className={`${classes.body} ${open ? classes.open : classes.close}`}>
        <ToggleButtonGroup
          className={classes.toggles}
          value={enabled}
          onChange={handleEnableChange}>
          <ToggleButton value='active-flights'>
            <Tooltip title='Active Flights'><Flight /></Tooltip>
          </ToggleButton>
          <ToggleButton value='geofences'>
            <Tooltip title='Geofences'><MySettingIcon /></Tooltip>
          </ToggleButton>
          <ToggleButton value='planned-flights'>
            <Tooltip title='Planned Flights'><FlightTakeoff /></Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
        { enabled.includes('active-flights') &&
          <ActiveFlights
            summary={summary}
            tracks={tracks}
            selectedFlightId={selectedFlightId}
            setSelectedFlightId={setSelectedFlightId}
          />
        }
        { enabled.includes('geofences') &&
          <ActiveGeofences
            selectedId={selectedGeofenceId}
            onClickGeofence={onClickGeofence}
            isRsaf={isRsaf}
          />
        }
        { enabled.includes('planned-flights') &&
          <UpcomingFlights
            onSelect={setSelectedFlightId}
            selected={selectedFlightId}
          />
        }
      </div>
    </div>
  )
}

export default withStyles(styles)(FlightCtrlPanel)

/* {enabled.includes('violations') && <RsafViolations
  violations={Object.values(violations?.entities || {})}
  onTrackerClick={onTrackerClick}
  onDroneClick={onDroneClick}
/>} */

