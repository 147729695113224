import { useEffect, useState } from 'react'
import { withRouter, useParams, useLocation } from 'react-router'
import { toast } from 'react-toastify'
import {
  getDecodedAccessToken,
  useLazyGetUserByIdQuery,
} from '../../api/accounts'
import {
  useCreateFRZMutation,
  useUpdateFRZMutation,
  useSubmitFRZForApprovalMutation,
  useLazyGetFRZByIdQuery
} from '../../api/airspace'
import { useLazyGetDroneByIdQuery } from '../../api/aircraft'

import useCompanies        from '../../components/useCompanies'
import WaitDialog          from '../../components/WaitDialog'
import StepperBanner       from './components/StepperBanner'
import StepperActions      from './components/StepperActions'
import ReservationDetails  from './Details'
import ReservationConfirm  from './Confirm'
import ReservationApproval from './Approval'
import ReservationComplete from './Complete'

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    width: '100%',
    height: '100vh',
    margin: theme.spacing(1),
    overflow: 'hidden',
  },
  content: {
    height: `calc(100vh - ${theme.spacing(21)}px)`,
    overflow: 'scroll',
  },
})

function Reservation({ classes, history }) {
  const { zone_id }= useParams()
  const queryParams = new URLSearchParams(useLocation().search)

  const [ frz,        setFrz        ] = useState(null)
  const [ frzOk,      setFrzOk      ] = useState(!!zone_id)
  const [ conflict,   setConflict   ] = useState('')
  const [ activeStep, setActiveStep ] = useState(parseInt(queryParams.get('step')) || 0)
  const [ loading,    setLoading    ] = useState(false)

  const [ loadingMsg, setLoadingMsg ] = useState('')
  const { company_ids } = useCompanies()

  const [ createFRZ ] = useCreateFRZMutation()
  const [ updateFRZ ] = useUpdateFRZMutation()
  const [ submitFRZ ] = useSubmitFRZForApprovalMutation()

  const [ getFRZById, result ] = useLazyGetFRZByIdQuery()
  const [ getDroneById ] = useLazyGetDroneByIdQuery()
  const [ getPilotById ] = useLazyGetUserByIdQuery()
  
  const detailsReady = (zone_id && !result.isLoading && frz  && company_ids?.length > 0) || (!zone_id && company_ids?.length > 0)
  
  const decodedToken = getDecodedAccessToken()
  const userId = decodedToken.sub
  const isApprover = frz?.status === 'pending-approval' && frz?.approval_chain?.find(((ac)=> ac?.approver_user_id === userId))

  function upsertFrz(mutator) {
    setLoading(true)
    setLoadingMsg('Creating FRZ...')
    const body = {
      type:         frz.type,
      company_id:   frz.pilots[0].company.company_id,
      name:         frz.name,
      description:  frz.desc,
      status: frz.status ? frz.status : 'draft',
      pilots: frz.pilots.map(pilot => ({
        name:       pilot.name,
        user_id:    pilot.user_id,
        company_id: pilot.company.company_id,
      })),
      aircrafts: frz.aircrafts.map((drone)=>  ({ // TODO handle aircraft is drone or tracker
        name:       drone.name,
        drone_id:   drone.drone_id,
        company_id: drone.company_id,
      })),
      altitude: frz.altitude,
      validity: (!Array.isArray(frz.validity) ? [{
        start:      frz.validity.start.getTime(),
        end:        frz.validity.end.getTime(),
      }] : frz.validity),
    }

    if (frz.frz_id) {
      body.frz_id = frz.frz_id
    }
    if (frz.geometry) {
      body.geometry = { // remove anything that geoman adds
        type:        frz.geometry.type,
        coordinates: frz.geometry.coordinates,
      }
    } else if (frz.zone) {
      body.source_nfz = { nfz_id: frz.zone.nfz_id }
    } else {
      body.geometry = {} // Invalid but don't crash
    }
    
    mutator(body).unwrap()
    .then(fulfilled => {
      setLoading(false)
      setLoadingMsg('')
      console.log('FRZ upsert fulfilled', fulfilled)
      if (fulfilled.status !== 'success') {
        if (fulfilled.data?.conflict) {
          toast.warning('This airspace has already been reserved.')
          setConflict(fulfilled?.data?.conflict)
          setActiveStep(0)
        } else if (activeStep === 0) {
          toast.error(`Failed to save draft. ${fulfilled?.data?.frz || ''}`)
        } else if (activeStep === 1) {
          toast.error(`Failed to reserve airspace. ${fulfilled?.data?.frz || ''}`)
        }
        return
      }
      setConflict('')

      if (!frz.frz_id) {
        setFrz({ ...frz, frz_id: fulfilled.data.frz.frz_id })
      }

      if (activeStep === 0) {
        toast.info(`${isApprover ? 'Changes to FRZ Request saved' : 'Draft saved'}`)
        history.replace(`/zones/${fulfilled.data.frz.frz_id}/edit?step=0`, null)
        return // save draft
      }

      const ac = fulfilled.data?.frz?.approval_chain
      if (Array.isArray(ac) && ac.length > 0) {
        setFrz({ ...frz,
          frz_id: fulfilled.data.frz.frz_id,
          approval_chain: ac,
        })
        setActiveStep(2)
      }
      else {
        submitFrz(fulfilled.data.frz.frz_id)
      }
    })
    .catch(rejected => {
      console.log('FRZ upsert rejected', rejected)
      toast.error('Error reserving airspace, try again later.')
      setActiveStep(1)
    })
  }
  function submitFrz(frz_id = frz.frz_id) {
    setLoading(true)
    setLoadingMsg('Submitting FRZ for approval...')

    submitFRZ({ frz_id })
    .unwrap()
    .then(submitted => {
      setLoading(false)
      setLoadingMsg('')

      console.log('submitted FRZ', submitted)
      if (submitted.status !== 'success') {
        toast.error('Failed to submit airspace reservation for approval')
      } else {
        setActiveStep(3)
      }
    })
    .catch(rejected => {
      console.log('Error submiting FRZ', rejected)
      toast.error('Error submitting FRZ for approval, try again later.')
    })
  }

  function handleSaveDraft() {
    upsertFrz(frz.frz_id ? updateFRZ : createFRZ)
  }

  function handleNext() {
    if (activeStep === 0)
      setActiveStep(1)
    else if (activeStep === 1)
      upsertFrz(frz.frz_id ? updateFRZ : createFRZ)
    else if (activeStep === 2)
      submitFrz()
    else if (activeStep === 3)
      history.push('/zones')
    if (frz.frz_id && activeStep !== 3)
      history.replace(`/zones/${frz.frz_id}/edit?step=${activeStep+1}`, null)
  }

  function handleBack() {
    if (activeStep === 0){
      history.push('/zones')
      if (isApprover) {
        history.push(`/zones/${frz.frz_id}`)
      } else {
        history.push('/zones')
      }
    }
    else {
      setActiveStep(prevActiveStep => prevActiveStep - 1)
      if (frz.frz_id)
        history.replace(`/zones/${frz.frz_id}/edit?step=${activeStep - 1}`, null)
    }
  }

  useEffect(() => {
    if (!frz && zone_id) {
      setLoading(true)
      setLoadingMsg('Fetching FRZ information')
      getFRZById(zone_id)
        .then(async(r) => {
          setLoading(false)
          setLoadingMsg('')

          const aircrafts = r?.data?.data?.frz?.aircrafts || []
          const aircraftsInfo = await Promise.all(
            aircrafts?.map(a => getDroneById(a.drone_id).unwrap().then((r) => r?.data?.drone))
          )
          const pilots = r?.data?.data?.frz?.pilots || []
          const pilotsInfo = await Promise.all(
            pilots?.map(a => getPilotById(a.user_id).unwrap().then((r) => r?.data?.user))
          )
          setFrz((frz)=>{
            return {
              ...frz,
              ...r?.data?.data?.frz,
              aircrafts: aircraftsInfo,
              pilots: pilotsInfo
            }
          })
        })
    }
  }, [frz, getFRZById, zone_id, getDroneById, getPilotById])
  
  if (zone_id) {
    if (result.isLoading && company_ids?.length === 0) {
      return <WaitDialog msg='Loading FRZ...' />
    } else if (!result.isUninitialized) {
      if (result.error) {
        toast.error('Error loading FRZ')
        console.log('Error loading FRZ', result.error)
      } else if (result?.data?.status !== 'success' && !result.isFetching && !result.isLoading) {
        toast.error('Failed to load FRZ')
        console.log('Failed to load FRZ', result)
      }
    }
  }
  if (!detailsReady) {
    return <WaitDialog msg='Loading...' />
  }

  return (
    <div className={classes.root}>
      <StepperBanner activeStep={activeStep} />
      <div className={classes.content}>
        {
          loading && <WaitDialog msg={loadingMsg} />
        }
        { activeStep === 0 && detailsReady &&
          <ReservationDetails
            frz={frz}
            company_ids={company_ids}
            conflict={conflict}
            setFrz={setFrz}
            setFrzOk={setFrzOk}
          />
        }
        { activeStep === 1 && detailsReady &&
          <ReservationConfirm frz={frz} />
        }
        { activeStep === 2 &&
          <ReservationApproval frz={frz} />
        }
        { activeStep === 3 &&
          <ReservationComplete />
        }
      </div>
      <StepperActions
        frzOk={frzOk}
        activeStep={activeStep}
        onNext={handleNext}
        onBack={handleBack}
        onSaveDraft={handleSaveDraft}
        isApprover={isApprover}
      />
    </div>
  )
}

export default withRouter(withStyles(styles)(Reservation))
