import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useUpdateFlightMutation } from '../../api/aircraft'
import { useMap, GeoJSON } from 'react-leaflet'
import * as turf from '@turf/turf'

import EditFeatureGroupGeoman from '../../Zones/Editor/components/EditFeatureGroupGeoman'

function EditableFlightGeofence({ flight, editable }) {
  const map = useMap()
  const [ updateFlight ] = useUpdateFlightMutation()

  useEffect(() => {
    if (flight?.geofence) {
      const centroid = turf.centroid(flight.geofence).geometry.coordinates
      const centerLatLng = { lat: centroid[1], lng: centroid[0] }
      map.flyTo(centerLatLng)
    }
  }, [map, flight])

  const onSave = (geofence) => {
    updateFlight({
      ...flight,
      geofence,
    })
    .unwrap()
    .then(() => {
      toast.info('Updated flight geofence')
    })
  }

  if (!(flight?.geofence))
    return null
  return (editable ?
    <EditFeatureGroupGeoman
      geometry={flight.geofence}
      onSave={onSave}
    />
  : <GeoJSON data={flight.geofence} />)
}

export default EditableFlightGeofence
