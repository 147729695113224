import { format } from 'date-fns'
import Tracker from '../../components/Text/Tracker'
import Row from './Row.js'
import {
  Grid,
  Typography,
} from '@material-ui/core'

function Flight({ activeFlights, hasAvatar }) {
  const h = !!hasAvatar
  return (
    <Grid container>
      { !Array.isArray(activeFlights) || activeFlights.length === 0 ?
        <Typography variant='body1'>No Active Flight</Typography>
      : <>
          <Typography variant='body1'>Active Flight</Typography>
          { activeFlights.map(f => <Grid container key={f.flight_id}>
            <Row h={h} l='Started'  v={f.date ? format(new Date(f.date), 'eee dd MMM yyyy HH:mm:ss') : ''} />
            <Row h={h} l='Drone'    v={f.drone?.name} />
            <Row h={h} l='Pilot(s)' v={f.pilots?.map(p => p.name).join(', ')} />
            { Array.isArray(f.drone?.tracker_ids) &&
              <Row h={h} l='Tracker' v={f.drone.tracker_ids.map(id => <Tracker key={id} id={id} name />)} />
            }
            <Row h={h} l='Permits'  v={f.permits?.map(p => `${p.authority} ${p.ref}`)} />
          </Grid>) }
        </>
      }
    </Grid>
  )
}

export default Flight
