import { useRef, useState } from 'react'
import { toast }    from 'react-toastify'

import MyLocationMarker from '../components/Map/MyLocationMarker'
import TrackMarkers     from './components/TrackMarkers'
// import SingaporeWeather from '../components/Map/SingaporeWeather'
import PublicAirspace   from '../components/Airspace/Public'
import PrivateAirspace  from '../components/Airspace/Private'
import { withStyles } from '@material-ui/core/styles'

// Plugins
import DemoTracks           from './plugins/demo/DemoTracks'
import CfmsTrackMarkers     from './plugins/htx/CfmsTrackMarkers'
import CfmsFlightMarkers    from './plugins/htx/CfmsFlightMarkers'
import RsafMapComponents    from './plugins/rsaf/RsafMapComponents'
import XprizePlaceMarkers   from './plugins/xprize/XprizePlaceMarkers'
import XprizeTestEstates    from './plugins/xprize/XprizeTestEstates'
import XprizeFlightGeofence from './plugins/xprize/XprizeFlightGeofence'

// Leaflet
import 'leaflet/dist/leaflet.css'
import CommonLayers   from '../components/Map/CommonLayers'
import StickyPanZoom  from '../components/Map/StickyPanZoom'
import LockMapControl from '../components/Map/LockMapControl'
import {
  MapContainer,
  ScaleControl,
  ZoomControl,
} from 'react-leaflet'
import { DEFAULT_MAP_CENTER } from '../utils/site-maps'
import EditableFlightGeofence from './components/EditableFlightGeofence'
import { isController } from '../api/accounts'
import { MousePosition } from '../components/Map/MousePosition'
import Weather from '../components/Map/Weather'

const styles = theme => ({
  map: ({ fullWidth }) => ({
    width: fullWidth ? '100%' : 'calc(100% - 56px)',
    position: 'fixed',
    top: 0,
    right: 0,
    zIndex: 1,
  }),
  geolocate: {
    zIndex:   500,
    position: 'fixed',
    width:    theme.spacing(24),
    height:   theme.spacing(6),
    top:      '50%',
    left:     '50%',
    margin:   theme.spacing(-3, 0, 0, -12),
    display:  'flex',
    border:   `1px solid ${theme.palette.common.black}`,
    alignItems: 'center',
    borderRadius: theme.spacing(1),
    justifyContent: 'center',
    color:           theme.palette.common.black,
    backgroundColor: theme.palette.map.grey,
  },
})

function BaseMap({
  classes,
  showOnMap,
  company_ids,
  isHtx,
  isXprize,
  selectedFlight,

  hideMapControls,
  onDroneMarkerClicked, // legacy
  onTrackMarkerClicked, // only for tracks
  // Tracks
  tracks,
  drones,
  selectedDroneId,
  trackers,
  selectedTrackerId, // (or demo trackers)
  followMarker,

  // HTX plugin
  cfmsTracks,
  cfmsFlights,

  // RSAF plugin
  selectedGeofenceId,
  setSelectedGeofenceId,
  nfzs,
  selectedReadyToUse,
  setSelectedReadyToUse,
  rsafEditingFlightGeofence
  
  // XPRIZE plugin

}) {
  const freezeCtl = useRef(false)
  const [ ready, setReady ] = useState(false)
  const [ geolocate, setGeolocate ] = useState(false)
  const [ selectingTemplate, setSelectingTemplate] = useState(false)
  
  return (
    <MapContainer className={classes.map}
      center={DEFAULT_MAP_CENTER}
      minZoom={2}
      zoom={12}
      zoomControl={false}
      whenReady={() => { setReady(true) }}>
      <CommonLayers hideControls={hideMapControls} freezeControls={freezeCtl.current} />
      { !hideMapControls && <>
        { !freezeCtl.current &&
          <ZoomControl position='topleft' />
        }
        <ScaleControl position='bottomleft' imperial={false} />
        <LockMapControl position='topleft'
          onLock={() => { freezeCtl.current = true }}
          onUnlock={() => { freezeCtl.current = false }}
        />
        <MousePosition />
      </> }
      <PublicAirspace
        showStaticNfz={showOnMap['space-static-nfz']}
        showTempNfz={showOnMap['space-temp-nfz']}
        /* TODO:
        showOnMap['space-nparks-nfz']
        */
        showVessels={showOnMap['space-vessel-nfz']}
        showTestEstate={showOnMap['space-estate-nfz']}
        showCfmsNfz={showOnMap['htx-nfz']}
        isLocked={freezeCtl}
        // selectedNfzId={selectedNfzId}
      />
      { ready &&
        <StickyPanZoom />
      }
      { showOnMap['drone-tracks'] &&
        <TrackMarkers
          tracks={tracks}
          trackers={trackers}
          drones={drones}
          selectedTrackerId={selectedTrackerId}
          selectedDroneId={selectedDroneId}
          onClick={onTrackMarkerClicked}
          followMarker={followMarker}
        />
      }
      { showOnMap['plugins-demo'] &&
        <DemoTracks
          show={showOnMap['plugins-demo']}
          selectedTrackerId={selectedTrackerId}
          onClick={onTrackMarkerClicked}
        />
      }
      { showOnMap['weather'] &&
        // <SingaporeWeather />
        <Weather />
      }
      { /* TODO: Site-wide PlaceMarkers showOnMap['space-places'] */ }
      { isXprize && showOnMap['xprize-places'] &&
        <XprizePlaceMarkers company_ids={company_ids} />
      }
      { isXprize && showOnMap['xprize-flights'] && <>
        <XprizeTestEstates company_ids={company_ids} />
        <XprizeFlightGeofence flight={selectedFlight} />
      </>}
      
      { ready && selectedFlight &&
        <EditableFlightGeofence flight={selectedFlight} editable={isController()} />
      }
      { ready && showOnMap['rsaf-geofences'] &&
        <>
          { !selectedFlight && <RsafMapComponents
            selectedRsafGeofenceId={selectedGeofenceId}
            setSelectingTemplate={setSelectingTemplate}
            />
          }
        </>
      }
      { ready && company_ids && <PrivateAirspace
            company_ids={company_ids}
            showNfz={showOnMap['rsaf-geofences']}
            showReadyToUse={showOnMap['space-own-estate'] && !selectingTemplate}
            showPendingFrzs={showOnMap['space-pending-frz']}
            showApprovedFrzs={showOnMap['space-approved-frz']}
            onSelectNfz={(nfz)=>{
              setSelectedReadyToUse(nfz.nfz_id)
            }} 
            onSelectGeofence={(nfz)=>{
              setSelectedGeofenceId(nfz.nfz_id)
            }}
          />
      }
      { isHtx && showOnMap['htx-flights'] &&
        <CfmsFlightMarkers cfmsFlights={cfmsFlights} />
      }
      { isHtx && showOnMap['htx-tracks'] &&
        <CfmsTrackMarkers cfmsTracks={cfmsTracks} onClick={onDroneMarkerClicked} />
      }
      { showOnMap['space-my-location'] &&
        <MyLocationMarker
          onStart={() => { setGeolocate(true) }}
          onSuccess={() => { setGeolocate(false) }}
          onFailure={() => {
            setGeolocate(false)
            toast.warning('Cannot find you. Did you enable geolocation on your browser?')
          }}
        />
      }
      { geolocate &&
        <div className={classes.geolocate}>
          Searching for your position...
        </div>
      }
    </MapContainer>
  )
}

export default withStyles(styles)(BaseMap)
