import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { getDecodedAccessToken, isNotController } from '../../../api/accounts'
import { useLazyGetFlightByIdQuery } from '../../../api/aircraft'
import { useLazyGetTrackerByIdQuery } from '../../../api/tracker'
import { sendWS } from '../../../api/conformance'
import { say }   from '../../../utils/speech'
import Pill from '../../../components/Pill'
import RsafViolatingUa from './RsafViolatingUa'
import RsafViolationToast from './RsafViolationToast'
import {
  List,
  Typography,
} from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  container: {
    padding: theme.spacing(1),
  },
  body: {
    padding: theme.spacing(1),
  },
  activeViolation: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.map.orange
  },
})

function sendAck(ua_id) {
  sendWS({
    event_type: 'VIOLATION_ACK',
    ua_id,
  })
}

const ACK_VALID_DURATION = 100000 // backend has it as 2 minutes, so we kiasu a bit 1:40

function RsafViolations({
  classes,
  violations,
  sendViolationAck,
  onDroneClick,
  onTrackerClick,
}) {
  const [ getFlightById ] = useLazyGetFlightByIdQuery()
  const [ getTrackerById ] = useLazyGetTrackerByIdQuery()
  const [ toasted, setToasted ] = useState({}) // ua_id => toastId
  const [ summary, setSummary ] = useState({})

  // RTK Query cache is violations.violation_id = {...violation}
  // To transform this to summary = {
  //   [ua_id]: {
  //     ua_telem_type:
  //     company_id:
  //     flight_id:
  //     areas: {
  //       [area_id]: {
  //         area_type:
  //         keep_in: {
  //           warning_since: created_on (for first WARNING-KEEPIN)
  //           breached_since: created_on (for first BREACHED-KEEPIN)
  //         },
  //         keep_out: {
  //           warning_since: created_on (for first WARNING-KEEPOUT)
  //           breached_since: created_on (for first BREACHED-KEEPOUT)
  //         },
  //       },
  //     },
  //     acknowledged: { [l_a_by]: l_a_on },
  //   }
  // }

  useEffect(() => {
    if (!violations || Object.keys(violations).length === 0) {
      setSummary({})
      return
    }
    const summ = {}
    const twoMinAgo = Date.now() - ACK_VALID_DURATION
    Object.keys(violations).forEach(v_id => {
      const v = violations[v_id]
      if (!summ[v.ua_id])
        summ[v.ua_id] = { areas: {}, acknowledged: {} }
      const s = summ[v.ua_id]
      if (!s.ua_telem_type)
        s.ua_telem_type = v.ua_telem_type
      if (!s.company_id)
        s.company_id = v.company_id
      if (v.flight_id && !s.flight_id)
        s.flight_id = v.flight_id
      if (!s.areas[v.area_id])
        s.areas[v.area_id] = { area_type: v.area_type, keep_in: {}, keep_out: {} }
      if (v.created_on) {
        if (v.status === 'WARNING-KEEPOUT')
          s.areas[v.area_id].keep_out.warning_since = v.created_on
        else if (v.status === 'BREACHED-KEEPOUT')
          s.areas[v.area_id].keep_out.breached_since = v.created_on
        else if (v.status === 'WARNING-KEEPIN')
          s.areas[v.area_id].keep_in.warning_since = v.created_on
        else if (v.status === 'BREACHED-KEEPIN')
          s.areas[v.area_id].keep_in.breached_since = v.created_on
      }
      if (v.last_acknowledged_by && v.last_acknowledged_on)
        s.acknowledged[v.last_acknowledged_by] = v.last_acknowledged_on
    })
    Object.values(summ).forEach(s => {
      Object.keys(s.acknowledged).forEach(user_id => {
        if (new Date(s.acknowledged[user_id]).getTime() < twoMinAgo)
          delete s.acknowledged[user_id]
      })
    })
    setSummary(summ)
  }, [violations])

  useEffect(() => {
    if (!summary || Object.keys(summary).length === 0)
      return

    const my_user_id = getDecodedAccessToken().user_id
    let ack_timeout

    function handleAck(ua_id) {
      toast.dismiss(toasted[ua_id])
      setToasted(prevToasted => ({ ...prevToasted, [ua_id]: -1 }))
      sendAck(ua_id)
      ack_timeout = setTimeout(() => {
        setToasted(prevToasted => {
          const nextToasted = { ...prevToasted }
          delete nextToasted[ua_id]
          return nextToasted
        })
      }, ACK_VALID_DURATION)
    }

    let make_noise = false
    Object.keys(summary).forEach(async ua_id => {
      const v = summary[ua_id]
      if (Object.keys(v.acknowledged).length > 0 || toasted[ua_id])
        return

      // Two sets of audience: those we just want to notify, and those who must acknowledge
      let ackEnabled = false

      // If flight exists, only require the pilots on the flight to acknowledge
      if (v.flight_id) {
        const result = await getFlightById(v.flight_id).unwrap()
        console.log('get flight result', result)
        if (result?.status === 'success' &&
          result.data?.flight?.pilots?.some(p => p.id === my_user_id))
          ackEnabled = true
      }
      // Otherwise, if tracker has an owner, only require the tracker owner to acknowledge
      else if (v.ua_telem_type === 'tracker') {
        const result = await getTrackerById(ua_id).unwrap()
        console.log('get tracker result', result)
        if (result?.status === 'success' &&
          result.data?.tracker?.owner?.user_id === my_user_id)
          ackEnabled = true
      }
      // Otherwise, current best effort is to ask anyone who's not a controller to acknowledge
      else
        ackEnabled = isNotController() // Note this is different from !isController
      // TODO: Other conditions where the user is allowed to ACK

      const toastId = toast.warn(
        <RsafViolationToast
          ua_id={ua_id}
          info={summary[ua_id]}
          ackEnabled={ackEnabled}
          onAck={handleAck}
        />, {
          position: 'top-center',
          autoClose: ackEnabled ? false : 3000,
          hideProgressBar: true,
          closeOnClick: false,
          draggable: false,
        })
      setToasted(prevToasted => ({ ...prevToasted, [ua_id]: toastId }))
      make_noise = true
    })
    if (make_noise) {
      say('Alert, airspace breached.')
      // say('警报，无人机超出领空。', { lang: 'zh-SG' }) // TODO i18n/prefs
    }
    return () => {
      if (ack_timeout)
        clearTimeout(ack_timeout)
    }
  }, [summary, toasted, getFlightById, getTrackerById])

  if (!violations)
    return null
  
  return (<>
    <div className={classes.container}>
      { Object.keys(summary).length === 0 ?
        <div className={classes.body}>
          <Pill text='No Violations' />
        </div>
      : <div className={classes.body}>
          <Typography variant='overline'>Violations</Typography>
          <List>
            { Object.keys(summary).map(ua_id => (
              <RsafViolatingUa key={ua_id}
                ua_id={ua_id}
                summary={summary[ua_id]}
                onDroneClick={onDroneClick}
                onTrackerClick={onTrackerClick}
              />
            )) }
          </List>
        </div>
      }
    </div>
  </>)
}

export default withStyles(styles)(RsafViolations)
