import { withRouter } from 'react-router'
import { useGetMyCompanyQuery } from '../api/accounts'
import usePermissions from '../components/usePermissions'
import TableView    from './TableView'
import MapView      from './MapView'

function Playback({ classes, location }) {
  const trackId = location.pathname.split('/playback/')[1]
  const { data, isLoading } = useGetMyCompanyQuery()
  const isRsaf = data?.data?.company?.properties?.pref_enable_rsaf === true
  const canPlayback = usePermissions('rsaf-playback-read', { warn: true })

  // TODO: Redo this permission check because non RSAF company is getting both warning toast but able to see playback

  if (isLoading || (isRsaf && !canPlayback))
    return null

  return trackId ?
    <MapView trackId={trackId} />
  : <TableView />
}

export default withRouter(Playback)
