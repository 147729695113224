import User         from '../../../components/Text/User'
import { getValidity } from '../../../utils/frz'
import FrzMap       from '../../components/FrzMap'
import {
  Grid,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  title: {
    padding: theme.spacing(1, 2),
  },
  important: {
    marginTop: theme.spacing(2),
    fontWeight: 'bold'
  }
})

function ReservationApproval({ classes, frz, onSubmit }) {
  if (!frz)
    return null

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <div className={classes.title}>
          <Typography variant='h6'>{frz?.name}</Typography>
        </div>
        <FrzMap
          hideControls
          groundPoint={frz?.altitude?.ground_point}
          geometry={frz?.zone?.geometry ? frz.zone.geometry : frz?.geometry}
          validity={getValidity(frz)}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant='subtitle1' className={classes.important}>
          Approvals Required
        </Typography>
        <Typography variant='body1'>
          Your airspace reservation requires the approval from the following
          individuals. Once you submit your reservaion for approval, we will
          notify them to approve this application in this order:
          <ol>
            {
              frz.approval_chain?.map((step, i) => (
                <li key={i}>
                  {
                    step.approver_label ?? (
                      step.approval_type === 'by_mdf_roles' ? 'Roles: ' + step.approver_mdf_role.join(', ')
                      : step.approval_type === 'by_user' ? <User id={step.approver_user_id} name email/>
                      : step.approver_utm_provider
                    )
                  }
                  { step.approver_company_name && ` (${step.approver_company_name})` }
                </li>
              ))
            }
          </ol>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(ReservationApproval)
