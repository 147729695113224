import {
  Grid,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import FrzProps from '../components/FrzProps'
import FrzMap   from '../../components/FrzMap'
import { getValidity } from '../../../utils/frz'

const styles = theme => ({
  title: {
    padding: theme.spacing(1, 2),
  },
})

function ReservationConfirm({ classes, frz }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <div className={classes.title}>
          <Typography variant='h6'>{frz?.name}</Typography>
        </div>
        <FrzMap
          hideControls
          validity={getValidity(frz)}
          groundPoint={frz?.altitude?.ground_point}
          geometry={frz?.zone?.geometry ? frz.zone.geometry : frz.geometry}
        />
      </Grid>
      <Grid item xs={6}>
        <FrzProps frz={frz} />
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(ReservationConfirm)
