import { useEffect, useState } from 'react'

import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import {
  GeoJSON,
  MapContainer,
  Marker,
  LayersControl,
  ScaleControl,
  ZoomControl
} from 'react-leaflet'
import { withStyles } from '@material-ui/core/styles'
import * as turf from '@turf/turf'

import { useGetPublicNFZsQuery } from '../../api/airspace'
import { useGetFRZsQuery, useGetPrivateNFZsQuery } from '../../api/airspace'

import pushpin          from '../../assets/pushpin.svg'
import PublicAirspace   from '../../components/Airspace/Public'
import PrivateAirspace  from '../../components/Airspace/Private'
import ReservedAirspace from '../../components/Airspace/Reserved'
import CommonLayers     from '../../components/Map/CommonLayers'
import MyLocationMarker from '../../components/Map/MyLocationMarker'
import StickyPanZoom    from '../../components/Map/StickyPanZoom'

import { DEFAULT_MAP_CENTER } from '../../utils/site-maps'
import theme                  from '../../utils/site-theme'

import EditFeatureGroup from '../Editor/components/EditFeatureGroup'
import FrzMapControls   from './FrzMapControls'
import { Box } from '@material-ui/core'

const styles = () => ({
  container: {
    width:  '100%',
    height: 'inherit',
  },
  map: {
    width:  '100%',
    height: '100%',
    minHeight: '400px'
  },
  crosshair: {
    cursor: 'crosshair'
  }
})

function FrzMap({
  frz_id,
  classes,
  company_ids,
  hideControls,
  geometry,
  originalGeometry,
  validity,
  setGeometry,
  showDrawControls = false,
  showEditControls = false,
  selectMode = false,
  onSelectPoint,
  groundPoint
}) {
  
  const [ map, setMap ] = useState(null)
  const [ myLocation, setMyLocation ] = useState('disabled') // finding, enabled
  const [ geoCenter,  setGeoCenter  ] = useState()
  const [ showOnMap,  setShowOnMap  ] = useState([
    // 'public-nfz',
    // 'public-test-estate',
    // 'private-nfz',
    // 'draft-frz',
    // 'pending-approval-frz',
    // 'approved-frz',
    // 'rejected-frz',
  ])
  const [ conflictMessage, setConflictMessage ] = useState('')
  const [ conflictGeometry, setConflictGeometry ] = useState(null)

  const { data: nfzData } = useGetPublicNFZsQuery({
    validity_start: validity?.start / 1000,
    validity_end:   validity?.end   / 1000,
  })
  const { data: frzData } = useGetFRZsQuery({
    validity_start: validity?.start / 1000,
    validity_end:   validity?.end   / 1000,
  })
  const { data: privateNfzData } = useGetPrivateNFZsQuery({
    validity_start: validity?.start / 1000,
    validity_end:   validity?.end   / 1000,
  })
  
  function onMapFeatureChanged(e) {
    setGeometry(e)
  } 

  useEffect(() => {
    if (selectMode && map) {
      map.getContainer().style.cursor = 'crosshair'
      const cb = (e) => onSelectPoint(e.latlng)
      map.on('click', cb)
      return () => {
        map.off('click', cb)
        map.getContainer().style.cursor = ''
      }
    }
  }, [selectMode, map, onSelectPoint])

  useEffect(() => {
    if (!geometry || !validity) return setConflictMessage('')

    if (nfzData?.entities)
      for (let z of Object.values(nfzData?.entities).filter(e => e?.geometry?.type === 'Polygon'))
        if (turf.booleanIntersects({ type: 'Feature', geometry: geometry }, { type: 'Feature', geometry: z.geometry})) {
          setConflictGeometry(z.geometry)
          setConflictMessage(`Conflicted airspace: ${z.description}`)
          return 
        }
    if (frzData?.entities)
      for (let z of Object.values(frzData?.entities).filter(e => e?.geometry?.type === 'Polygon'))
        if (turf.booleanIntersects({ type: 'Feature', geometry: geometry }, { type: 'Feature', geometry: z.geometry})) {
          setConflictGeometry(z.geometry)
          setConflictMessage(`Conflicted airspace: ${z.description}`)
          return 
        }
    if (privateNfzData?.entities)
      for (let z of Object.values(privateNfzData?.entities).filter(e => e?.geometry?.type === 'Polygon'))
        if ((z.nfz_id !== frz_id) && turf.booleanIntersects({ type: 'Feature', geometry: geometry }, { type: 'Feature', geometry: z.geometry})) {
          setConflictGeometry(z.geometry)
          setConflictMessage(`Conflicted airspace: ${z.description}`)
          return 
        }
      setConflictGeometry(null)
      setConflictMessage('')
  }, [nfzData, frzData, privateNfzData, frz_id, geometry, validity])

  const groundPointIcon = L.icon({
    iconUrl: pushpin,
    iconAnchor: [12, 24]
  })
  
  return (
    <div className={classes.container}>
      <MapContainer
        minZoom={2}
        zoom={12}
        zoomControl={false}
        center={DEFAULT_MAP_CENTER}
        ref={setMap}
        className={classes.map}>
        { originalGeometry && <LayersControl position='topright' collapsed={false}>
          <LayersControl.Overlay name="Show Reservation Boundary">
            <GeoJSON data={originalGeometry} style={{color: theme.palette.map.black}}/>
          </LayersControl.Overlay>
        </LayersControl>
        }
        { conflictGeometry && 
          <GeoJSON 
            key={JSON.stringify(conflictGeometry)}
            data={{
              type: 'Feature',
              geometry: conflictGeometry}}
              style={{color: 'red'}}/> 
        }
        <StickyPanZoom></StickyPanZoom>
        <CommonLayers />
        <ZoomControl position='topright' />
        <ScaleControl position='bottomleft' imperial={false} />
        { groundPoint && <Marker position={[groundPoint.lat, groundPoint.lng]} icon={groundPointIcon}></Marker>}
        <EditFeatureGroup
          geometry={geometry}
          onCreated={onMapFeatureChanged}
          onDeleted={onMapFeatureChanged}
          onEdited={onMapFeatureChanged}
          featureStyle={{color: theme.palette.map.lightBlue}}
          geoCenter={geoCenter}
          setGeoCenter={setGeoCenter}
          draw={{
            polygon:   showDrawControls,
            circle:    false,
            rectangle: false,
            polyline:  false,
            marker:    false,
            circlemarker: false,
          }}
          edit={{
            edit: showEditControls,
            remove: showEditControls
          }}
        />
        
        { myLocation !== 'disabled' &&
          <MyLocationMarker
            onSuccess={() => { setMyLocation('enabled') }}
            onFailure={() => { setMyLocation('disabled') }}
          />
        }
        { (showOnMap.includes('public-nfz') || showOnMap.includes('public-test-estate')) &&
          <PublicAirspace
            showStaticNfz={showOnMap.includes('public-nfz')}
            showTempNfz={showOnMap.includes('public-nfz')}
            showTestEstate={showOnMap.includes('public-test-estate')}
          />
        }
        { (showOnMap.includes('private-nfz') || showOnMap.includes('private-ready-to-use')) &&
          <PrivateAirspace
            company_ids={company_ids}
            showReadyToUse={showOnMap.includes('private-ready-to-use')}
            showNfz={showOnMap.includes('private-nfz')}
          />
        }
        { showOnMap.some(v => v.substr(-4) === '-frz') &&
          <ReservedAirspace
            company_ids={company_ids}
            showStatuses={
              showOnMap
              .filter(v => v.substr(-4) === '-frz')
              .map(v => v.slice(0, -4))
            }
          />
        }
      </MapContainer>
      { !hideControls &&
        <FrzMapControls
          map={map}
          showOnMap={showOnMap}
          setShowOnMap={setShowOnMap}
          myLocation={myLocation}
          setMyLocation={setMyLocation}
        />
      }
      { conflictMessage && <Box sx={{color: 'red'}}>{conflictMessage}</Box>}
    </div>
  )
}

export default withStyles(styles)(FrzMap)
