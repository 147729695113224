import Sockette  from 'sockette'
import moment    from 'moment'
import isEqual   from 'fast-deep-equal'
import { warnAndSay, infoAndSay } from '../utils/speech'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { createEntityAdapter } from '@reduxjs/toolkit'
import { makeQueryString, makeWssUrl, standardHeaders } from './api-utils'
import { getDecodedAccessToken } from './accounts'

const AIRSPACE_HTTP_API = new URL(process.env.REACT_APP_AIRSPACE_HTTP_API).href
const AIRSPACE_WS_API = new URL(process.env.REACT_APP_AIRSPACE_WS_API).href

export function defaultNfzValidity() {
  const start = moment().startOf('day').unix()
  const end = moment().add(14, 'days').startOf('day').unix()
  return { start, end }
}

let public_airspace_ws       // Sockette
let public_airspace_real_ws  // WebSocket inside

let private_airspace_ws      // Sockette
let private_airspace_real_ws // WebSocket inside

const wsCache = {}
const public_airspace_adapter = createEntityAdapter({
  selectId: nfz => nfz.nfz_id,
  // unsorted (no sortComparer, probably don't need)
})
const private_airspace_adapter = createEntityAdapter({
  selectId: nfz => nfz.nfz_id,
  // unsorted (no sortComparer, to migrate in from RSAF)
})

async function openPublicAirspaceWS(url) {
  return new Promise(function (resolve, reject) {
    if (wsCache[url])
      return resolve()
    public_airspace_ws = wsCache[url] = new Sockette(url, {
      timeout: 10000,
      maxAttempts: Infinity,
      onopen: e => {
        console.info('public airspace ws connected')
        public_airspace_real_ws = e.target
        resolve(public_airspace_ws)
      },
      onreconnect: e => console.info('public airspace ws reconnecting'),
      onclose: e => console.info('public airspace ws close'),
      onerror: e => console.info('public airspace ws error', e),
    })
  })
}

async function openPrivateAirspaceWS(url) {
  return new Promise(function (resolve, reject) {
    if (wsCache[url])
      return resolve()
    private_airspace_ws = wsCache[url] = new Sockette(url, {
      timeout: 10000,
      maxAttempts: Infinity,
      onopen: e => {
        console.info('private airspace ws connected')
        private_airspace_real_ws = e.target
        resolve(private_airspace_ws)
      },
      onreconnect: e => console.info('private airspace ws reconnecting'),
      onclose: e => console.info('private airspace ws close'),
      onerror: e => console.info('private airspace ws error', e),
    })
  })
}

// Only toast warn and say NFZs that are NEW (i.e. first time seeing from WS,
// and not previously found in cache) Don't care about ships (using roundabout
// way to know vessel NFZs though - for discussion)
function announcePublicNfz(batchData) {
  const warnData = Object.values(batchData).filter(nfz => (
    nfz.type === 'temp' &&
    nfz.status === 'active' &&
    nfz.restriction === 'operation-restriction' &&
    nfz.geometry.type !== 'Point'
  ))
  if (warnData.length === 0)
    return

  warnAndSay(warnData.length === 1 ?
    'Warning, new public flight restriction, ' + warnData[0].name + ', is now active' :
    'Warning, ' + warnData.length + ' new public flight restrictions are now active: ' +
    warnData.map(wd => wd.name).join(', '))
}

// https://redux-toolkit.js.org/rtk-query/usage/streaming-updates
// O(1) for cache lookup
// originallly had this block before figuring out useDebounce, but keeping around this for batching updates to cache
// update cache if there are no messages for more than 1 second

export const airspaceApi = createApi({
  reducerPath: 'airspaceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: AIRSPACE_HTTP_API,
    prepareHeaders: standardHeaders,
  }),
  tagTypes: ['PublicNFZs', 'PrivateNFZs', 'FRZs'],
  endpoints: (builder) => ({
    getPublicNFZs: builder.query({
      query: (params) => (`/nfzs${makeQueryString(params, [
        'type',
        'restriction',
        'name',
        'description',
        'data_provider_name',
        'owner_name',
        'geo_point',
        'geo_coordinate',
        'created_start',
        'created_end',
        'updated_start',
        'updated_end',
        'end_date',
        'altitude',
        'validity_start',
        'validity_end',
        'data_provider_reference',
      ])}`),
      async transformResponse(response, meta, arg) {
        // console.log('getPublicNFZs transformResponse response', response)
        if (response.status === 'success') {
          return public_airspace_adapter.addMany(
            public_airspace_adapter.getInitialState(),
            response.data.nfzs
          )
        } else {
          console.log('failed to transform nfz', response)
          return public_airspace_adapter.getInitialState()
        }
      },
      providesTags: (result, error, arg) => {
        // console.log('getPublicNFZs providesTags result', result)
        return result ?
          [...result.ids.map(id => ({ type: 'PublicNFZs', id })), 'PublicNFZs'] :
          ['PublicNFZs']
      },
      async onCacheEntryAdded(arg, { getCacheEntry, updateCachedData, cacheEntryRemoved, cacheDataLoaded }) {
        try {
          await cacheDataLoaded
          await openPublicAirspaceWS(`${AIRSPACE_WS_API}/nfzs`)
          if (!public_airspace_ws || !public_airspace_real_ws)
            return console.log('public airspace ws did not open', AIRSPACE_WS_API)

          let batchData = {} // Without TypeScript, not sure if this would resolve to Record<EnitityId, T> correctly for upsertMany
          let batchInterval
          public_airspace_real_ws.addEventListener('message', event => {
            let data
            try {
              data = JSON.parse(event.data)
            } catch(e) {
              return console.log(e)
            }
            const new_nfz = data?.data?.nfzs

            if (data?.event_type === 'DELETED') {
              updateCachedData(draft => {
                console.log('deleting public nfz', new_nfz)
                public_airspace_adapter.removeOne(draft, new_nfz.nfz_id)
              })
              infoAndSay(`Public flight restriction, ${new_nfz.name}, is no longer active`)
            }
            else { // CREATED / UPDATED
              const cache = getCacheEntry().data.entities
              if (!isEqual(new_nfz, cache[new_nfz.nfz_id]))
                batchData[new_nfz.nfz_id] = new_nfz
              if (batchInterval)
                clearTimeout(batchInterval)
              batchInterval = setTimeout(function() {
                updateCachedData(draft => {
                  console.log(`batch upserting ${Object.keys(batchData).length} public nfzs`)
                  // console.log('batch upserting public nfzs', batchData)
                  public_airspace_adapter.upsertMany(draft, batchData)
                })
                announcePublicNfz(batchData)
              }, 1000)
            }
          })
        } catch {} // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`, in which case `cacheDataLoaded` will throw
        await cacheEntryRemoved
        console.log('public nfz cache entry removed, closing ws')
        public_airspace_ws.close()
      },
    }),
    getPrivateNFZs: builder.query({
      query: (params) => {
        // To ensure that private NFZs always work, at least one company ID must be passed in
        // otherwise the call is interpreted as a Public NFZ
        const _params = { ...params }
        if (!(
          (Array.isArray(_params.company_ids) && _params.company_ids.length > 0) ||
          (typeof _params.company_ids === 'string' && _params.company_ids.length > 0)
        )) {
          _params.company_ids = getDecodedAccessToken().company_id
          // console.log('overwrite company_ids', params.company_ids, 'with', _params.company_ids)
        }
        return `/nfzs${makeQueryString(_params, [
          'type',
          'restriction',
          'name',
          'description',
          'data_provider_name',
          'owner_name',
          'company_ids',
          'geo_point',
          'geo_coordinate',
          'created_start',
          'created_end',
          'updated_start',
          'updated_end',
          'end_date',
          'altitude',
          'validity_start',
          'validity_end',
          'data_provider_reference',
        ])}`
      },
      async transformResponse(response, meta, arg) {
        // console.log('getPrivateNFZs transformResponse response', response)
        if (response.status === 'success') {
          return private_airspace_adapter.addMany(
            private_airspace_adapter.getInitialState(),
            response.data.nfzs
          )
        } else {
          console.log('failed to transform nfz', response)
          return private_airspace_adapter.getInitialState()
        }
      },
      providesTags: (result, error, arg) => { // rmb: result is the transformed entity shape
        // console.log('getPrivateNFZs providesTags result', result)
        return result ?
          [...result.ids.map(id => ({ type: 'PrivateNFZs', id })), 'PrivateNFZs'] :
          ['PrivateNFZs']
      },
      async onCacheEntryAdded(arg, { getCacheEntry, updateCachedData, cacheEntryRemoved, cacheDataLoaded }) {
        try {
          await cacheDataLoaded
          const ws_url = makeWssUrl(`${AIRSPACE_WS_API}/nfzs`, {
            company_ids: arg?.company_ids
          })
          await openPrivateAirspaceWS(ws_url)
          if (!private_airspace_ws || !private_airspace_real_ws)
            return console.log('private airspace ws did not open', ws_url)

          private_airspace_real_ws.addEventListener('message', event => {
            let data
            try {
              data = JSON.parse(event.data)
            } catch(e) {
              return console.log(e)
            }
            const new_nfz = data?.data?.nfzs

            // bandage: ships still entering private NFZ
            if (new_nfz.type === 'temp' &&
                new_nfz.status === 'active' &&
                new_nfz.restriction === 'operation-restriction' &&
                new_nfz.geometry.type === 'Point')
              return //console.log('discarding public nfz ' + new_nfz.name + ' that came from private airspace ws')

            if (data?.event_type === 'DELETED') {
              updateCachedData(draft => {
                console.log('deleting private nfz', new_nfz)
                private_airspace_adapter.removeOne(draft, new_nfz.nfz_id)
              })
              if (new_nfz.restriction !== 'ready-to-use' && new_nfz.restriction !== 'test-estate') {
                infoAndSay(`Temporary flight restriction, ${new_nfz.name}, is no longer active`)
              }
            }
            else { // CREATED / UPDATED
              updateCachedData(draft => {
                // console.log('upserting private nfz', new_nfz)
                private_airspace_adapter.upsertOne(draft, new_nfz)
              })
              if (new_nfz.restriction !== 'ready-to-use' && new_nfz.restriction !== 'test-estate') {
                warnAndSay(`Warning, new temporary flight restriction, ${new_nfz.name}, is now active`)
              }
            }
          })
        } catch {} // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`, in which case `cacheDataLoaded` will throw
        await cacheEntryRemoved
        console.log('private nfz cache entry removed, refuse to close ws')
        // private_airspace_ws.close()
      },
    }),
    // Split this by public and private too
    getNFZById: builder.query({
      query: (nfz_id) => `/nfzs/${nfz_id}`,
      providesTags: (result, error, arg) => {
        const tags = result?.status === 'success' ?
          [{ type: 'PrivateNFZs', id: result.data.nfz.nfz_id }, 'PrivateNFZs'] :
          ['PrivateNFZs']
        // console.log('getNFZById providesTags result', result, 'tags', tags)
        return tags
      },
    }),
    // TBD rename the next 3 CUD functions to operate only on PrivateNFZs
    createNFZ: builder.mutation({
      query: (newNfz) => ({
        url: '/nfzs',
        method: 'POST',
        body: newNfz,
      }),
      invalidatesTags: ['PrivateNFZs'],
    }),
    updateNFZ: builder.mutation({
      query: ({ nfz_id, ...newNfz }) => ({
        url: `/nfzs/${nfz_id}`,
        method: 'PATCH',
        body: newNfz,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'PrivateNFZs', id: arg.nfz_id }],
    }),
    deleteNFZ: builder.mutation({
      query: (nfz_id) => ({
        url: `/nfzs/${nfz_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'PrivateNFZs', id: arg.nfz_id }],
    }),

    // FRZs
    getFRZById: builder.query({
      query: (frz_id) => `/frzs/${frz_id}`,
      providesTags: ['FRZs'],
    }),
    getFRZs: builder.query({
      query: (params) => (`/frzs${makeQueryString(params, [
        'company_ids',
        'type',
        'name',
        'status',
        'pilot',
        'aircraft',
        'location',
        'min-alt',
        'max-alt',
        'before-validity',
        'after-validity',
        'on-approval-chain',
        'utm_provider_name',
        'utm_provider_reference',
      ])}`),
      providesTags: ['FRZs'],
    }),
    createFRZ: builder.mutation({
      query: (newFrz) => ({
        url: `/frzs`,
        method: 'POST',
        body: newFrz,
      }),
      invalidatesTags: ['FRZs'],
    }),
    updateFRZ: builder.mutation({
      query: ({ frz_id, ...newFrz }) => ({
        url: `/frzs/${frz_id}`,
        method: 'PATCH',
        body: newFrz,
      }),
      invalidatesTags: ['FRZs'],
    }),
    deleteFRZ: builder.mutation({
      query: (frz_id) => ({
        url: `/frzs/${frz_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['FRZs'],
    }),
    submitFRZForApproval: builder.mutation({
      query: ({ frz_id }) => ({
        url: `/frzs/${frz_id}/submit-for-approval`,
        method: 'POST'
      }),
      invalidatesTags: ['FRZs']
    }),
    approveFRZ: builder.mutation({
      query: ({ frz_id, notes, geometry, edits}) => ({
        url: `/frzs/${frz_id}/approve`,
        method: 'POST',
        body: {approval_notes: notes, approval_geometry: geometry, approval_edits: edits}
      }),
      invalidatesTags: ['FRZs']
    }),
    rejectFRZ: builder.mutation({
      query: ({ frz_id, notes }) => ({
        url: `/frzs/${frz_id}/reject`,
        method: 'POST',
        body: {rejection_notes: notes}
      }),
      invalidatesTags: ['FRZs']
    })
  }),
})

export const {
  useGetPublicNFZsQuery,
  useLazyGetPublicNFZsQuery,
  useGetPrivateNFZsQuery,
  useLazyGetPrivateNFZsQuery,
  useGetNFZByIdQuery,
  useCreateNFZMutation,
  useUpdateNFZMutation,
  useDeleteNFZMutation,
  useLazyGetFRZByIdQuery,
  useGetFRZsQuery,
  useGetFRZByIdQuery,
  useCreateFRZMutation,
  useUpdateFRZMutation,
  useDeleteFRZMutation,
  useSubmitFRZForApprovalMutation,
  useApproveFRZMutation,
  useRejectFRZMutation
} = airspaceApi
