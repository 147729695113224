import { useParams } from 'react-router'
import { toast }     from 'react-toastify'
import { useGetFRZByIdQuery } from '../../api/airspace'
import WaitDialog    from '../../components/WaitDialog'
import FrzApprovalChain from './components/FrzApprovalChain'
import FrzDetails from './components/FrzDetails'
import FrzMap from '../components/FrzMap'
import MainControls  from './components/MainControls'
import {
  Grid,
  Box
} from '@material-ui/core'
import { useMemo, useState} from 'react'
import { booleanEqual } from '@turf/turf'
import { getApprovedGeometry, getValidity } from '../../utils/frz'
import { getDecodedAccessToken } from '../../api/accounts'
function Approval() {
  const { zone_id } = useParams()
  const [ approvalEdits,    setApprovalEdits    ] = useState()
  const [ modifiedGeometry, setModifiedGeometry ] = useState()
  const [ selectedStep,     setSelectedStep     ] = useState(0)

  const {
    data:      frzData,
    error:     frzError,
    isLoading: frzIsLoading,
  } = useGetFRZByIdQuery(zone_id, { skip: !zone_id })
  
  const isGeometryDirty = useMemo(()=> {
    if(!frzData?.data?.frz) return false
    return (frzData?.data?.frz?.geometry && modifiedGeometry && !booleanEqual(modifiedGeometry, frzData?.data?.frz?.geometry))
  }, [modifiedGeometry, frzData?.data?.frz])
  
  if (frzIsLoading) {
    return <WaitDialog msg='Loading Zone...' />
  } else {
    if (frzError) {
      toast.error('Error loading FRZ')
      console.log('Error loading FRZ', frzError)
    } else if (frzData?.status !== 'success') {
      toast.error('Failed to load FRZ')
      console.log('Failed to load FRZ', frzData)
    }
  }

  const frz = frzData?.data?.frz || {}
  const geometry = getApprovedGeometry(frz, selectedStep)
  const {user_id, app_roles} = getDecodedAccessToken()

  const isApprover = frz.status === 'pending-approval' && (frz.approval_chain?.find((ac) => ac?.approver_user_id === user_id) || frz.approval_chain?.find((ac) => ac.approver_mdf_role.find((role)=> app_roles.my_drone_fleets.includes(role))))

  return (
    <Box p={2} width='100%'>
      <MainControls />
      <Box p={5}>
        { frz && <FrzDetails frz={frz} setApprovalEdits={setApprovalEdits} approvalEdits={approvalEdits}/> }
        <Box height={30} />
        <Grid container spacing={6}>
          <Grid item md={12} lg={6}>
            <FrzMap 
              frz_id={frz.frz_id}
              originalGeometry={frz?.geometry ?? frz?.source_nfz?.geometry}
              validity={getValidity(frz)}
              geometry={geometry} 
              showDrawControls={isApprover} 
              showEditControls={isApprover} 
              setGeometry={(g)=>{
                setModifiedGeometry(g)
            }}/>
          </Grid>
          <Grid item md={12} lg={6}>
            <FrzApprovalChain 
              setSelectedStep={setSelectedStep}
              frz={frz} 
              isGeometryDirty={isGeometryDirty} 
              geometry={modifiedGeometry} 
              approvalEdits={approvalEdits}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Approval
